const Header = () => {

    const headerContent = "Menu Here!";


    return (
        <h1> { headerContent }</h1>

    );

}

export default Header;