import User from './components/User';

const Home = (props) => {

    const expenses = props.expenses;
    const title = props.title;


    return (
        <div className="test">
            <h4> { title } </h4>
            {expenses.map((expense) => (
                <div className="another">
                <h2> { expense.title } </h2>
                <h5> { expense.amount } </h5>
            </div>
                    ))
            }
        </div>
    );

}

export default Home;